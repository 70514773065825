<template>
  <div>
    <Header />
    <Logo msg="手机号" />
    <Loading v-if="isLoading" />
    <div class="con">
      <div class="img">
        <img class="img" src="../../../assets/order/guanlian.png" alt="" />
      </div>
      <div class="guanlian">关联手机号</div>
      <div class="glnum">当前关联手机号:{{ phone }}</div>
      <div class="list">
        <div class="root">
          <el-form
            :model="Register"
            ref="RegisterForm"
            label-width="0"
            class="RegisterForm"
          >
            <el-form-item prop="phone">
              <el-input
                v-model="Register.phone"
                placeholder="请输入新手机号"
              ></el-input>
            </el-form-item>

            <el-form-item prop="验证码" class="code">
              <el-input
                v-model="Register.sendcode"
                placeholder="请输入验证码"
              ></el-input>
              <el-button
                type="button"
                @click="sendcode"
                :disabled="disabled"
                v-if="disabled == false"
                >发送验证码
              </el-button>
              <el-button
                type="button"
                @click="sendcode"
                :disabled="disabled"
                v-if="disabled == true"
                >{{ btntxt }}
              </el-button>
            </el-form-item>
            <!-- <el-form-item>
              <el-input
                v-model="Register.pass"
                placeholder="请输入新手机号"
              ></el-input>
            </el-form-item> -->
            <el-form-item>
              <el-button type="success" @click="submitForm">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <!-- <Baoyou /> -->
    <Foot />
    <el-button :plain="true" v-show="false"></el-button>
  </div>
</template>
<script>
import { post, get } from '@/utils/request';
import Header from '@/components/header.vue';
import Baoyou from '@/components/order/baoyou1.vue';
import Foot from '@/components/foot.vue';
import Logo from '@/components/order/safe/logo.vue';
export default {
  components: {
    Header,
    Baoyou,
    Foot,
    Logo,
  },
  data() {
    return {
      Register: {
        phone: '',
        sendcode: '',
        checkPass: '',
      },
      disabled: false,
      time: 0,
      btntxt: '重新发送',

      input3: '',
      select: '',
      myForm: {},
      phone: '',
      isLoading: true,
    };
  },
  created() {
    this.getorder();
  },
  methods: {
    getorder() {
      get('api/member/selMemberInfo').then((res) => {
        this.isLoading = false;
        this.phone = res.data.data.phone.replace(
          res.data.data.phone.substr(3, 4),
          '****'
        );
      });
    },
    //手机验证发送验证码
    sendcode() {
      this.isLoading = true;
      const reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/;
      if (this.Register.phone == '') {
        this.isLoading = false;
        this.$message.warning({
          message: '手机号不能为空',
        });
        return;
      }
      if (!reg.test(this.Register.phone)) {
        this.isLoading = false;
        this.$message.warning({
          message: '请输入正确的手机号',
        });
        return;
      } else {
        get('api/account/selPhoneCheck?phone=' + this.Register.phone).then(
          (req) => {
            this.isLoading = false;
            if (req.data.msg == '已注册') {
              this.$message.warning({
                message: '手机号已注册',
              });
            } else if (req.data.msg == '请正确填写手机号码') {
              this.$message.warning({
                message: '请正确填写手机号码',
              });
            } else {
              get(
                'api/account/msginformation?phone=' + this.Register.phone
              ).then((res) => {
                if (res.data.msg == '短信发送成功') {
                  this.$message.success({
                    message: '发送成功',
                  });
                  this.time = 60;
                  this.disabled = true;
                  this.timer();
                } else {
                  this.$message.warning({
                    message: '发送失败',
                  });
                }
              });
            }
          }
        );
      }
    },
    //60S倒计时
    timer() {
      if (this.time > 0) {
        this.time--;
        this.btntxt = this.time + 's后重新获取';
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = '获取验证码';
        this.disabled = false;
      }
    },
    submitForm() {
      this.isLoading = true;
      if (this.Register.sendcode == '' || this.Register.phone == '') {
        this.$message.warning({
          message: '请填写完整',
        });
        this.isLoading = false;
        return;
      }
      post('api/account/updatephone', {
        phone: this.Register.phone,
        code: this.Register.sendcode,
      }).then((res) => {
        this.isLoading = false;
        if (res.data.msg == '操作成功') {
          this.$message.success({
            message: '修改成功',
          });
          this.$router.push({
            name: 'Safe',
          });
        } else {
          this.$message.warning({
            message: '验证码输入错误',
          });
        }
      });
    },
    // submitForm() {
    //   const reg1 = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/;
    //   if (!reg1.test(this.Register.pass)) {
    //     this.$message({
    //       message: '密码须包含数字、字母两种元素，且密码位数为6-16位',
    //       center: true,
    //     });
    //     return;
    //   } else if (
    //     this.Register.phone &&
    //     this.Register.sendcode &&
    //     this.Register.pass &&
    //     this.Register.checkPass
    //   ) {
    //     if (this.Register.pass != this.Register.checkPass) {
    //       this.$message({
    //         message: '两次密码不一致',
    //         type: 'warning',
    //         center: true,
    //       });
    //     } else {
    //       post('f/api/register/updatePassword', {
    //         phone: this.Register.phone,
    //         code: this.Register.sendcode,
    //         password: this.Register.pass,
    //       }).then((res) => {
    //         if (res.data.msg == '操作成功') {
    //           this.$message({
    //             message: '修改成功',
    //             type: 'success',
    //             center: true,
    //           });
    //         } else {
    //           this.$message({
    //             message: '修改失败',
    //             type: 'error',
    //             center: true,
    //           });
    //         }
    //       });
    //     }
    //   } else {
    //     this.$message({
    //       message: '请填写完整',
    //       type: 'warning',
    //       center: true,
    //     });
    //   }
    // },
  },
};
</script>
<style lang="scss" scoped>
.con {
  height: 452px;
  margin: auto;
  .img {
    width: 48px;
    height: 48px;
    margin: 0px auto;
    margin-top: 40px;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .guanlian {
    width: 100%;
    font-size: 20px;
    color: #333;
    margin-top: 32px;
    text-align: center;
  }
  .glnum {
    font-size: 16px;
    color: #777;
    margin-top: 14px;
    text-align: center;
  }
  .list {
    width: 100%;
    margin-top: 30px;
    .root {
      background: url('http://img.hena360.cn/login_bj.jpg') no-repeat center;
      background-size: 100% 100%;
      height: 60px;
      width: 440px;
      margin: auto;

      form {
        width: 90%;
        margin-top: 20px;
        margin: auto;
        .code {
          .el-input {
            width: 65%;
            border-radius: 0px;
            float: left;
            display: inline-block;
          }
          .el-button {
            width: 35%;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-left: 0px;
            float: left;
            display: inline-block;
          }
        }
        .el-button--success {
          width: 100%;
          background-color: #245af2;
          border: none;
          color: #fff;
        }
      }
    }
  }
}
</style>
